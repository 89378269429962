@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Open+Sans:wght@800&display=swap');

/* App.css */
.app {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('./datadollars.png'); /* Replace with your image path */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

/* Add a black overlay */
.background::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay with 50% opacity */
}

.content {
  position: relative;
  z-index: 2;
  height: 100%; /* Ensure content takes full height */
  display: flex;
  flex-direction: column;
  justify-content:center; /* Vertically space the text slider and form */
  color: white;
  align-items: center;
  padding: 4vh;
}

.form-container {
  background-color: rgba(0,0,0,.7);
  border-radius: 10px;
  margin: 0 20px 50px;
  max-width: 300px;
  padding: 30px 30px 50px;
}



/* Style for input fields (you can customize this) */
.form-container input {
  width: 75%;
  padding: 10px;
  margin-bottom: 10px;
  border: none;
  background: none;
  border-bottom: 1px solid #fff;
  color: white;
  font-size: 16px;
}

/* Style for submit button (you can customize this) */
.form-container button {
  width: 98%;
  padding: 5px;
  margin-top: 15px;
  background-color: #ff861c;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  font-weight: bolder;
}

.form-container button:hover {
  background-color: white;
  color: black;
}
.text-slider {
  padding: 0px; /* Adjust padding as needed */
  text-align: center;
  max-width: 100%;
  height: 200px; /* Adjust the height as needed */
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  position: relative;
  color: #ff861c;
  font-family: 'Open Sans', sans-serif;
}

.text-slider-content {
  display: flex; /* Remove flex-direction: column; */
  align-items: center;
  justify-content: center;
  color: #ff861c;
  position: absolute;
  width: 90vw; /* Ensure full width within the text-slider container */
  height: 100%; /* Ensure full height within the text-slider container */
  top: 0; /* Center vertically */
}

/* .text-slider-item {
  font-size: 10vh; 
  text-align: center;
  margin: 0;
  padding: 20px;
  color: white;
  opacity: 0;
  animation-fill-mode: forwards;
  z-index: 3;
  position: absolute;
  width: 80vw;
}

@keyframes scrollText {
  0%, 100% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.text-slider-item.active {
  opacity: 1; */

/* ... Your existing CSS above ... */

/* ... Your existing CSS above ... */

/* ... Your existing CSS above ... */

/* ... Your existing CSS above ... */

/* ... Your existing CSS above ... */

/* Add your other CSS rules here */

.text-slider-item {
  font-size: 8vw;
  text-align: center;
  margin: 0;
  padding: 10px;
  color: transparent;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: opacity 2s ease-in-out;
  -webkit-text-stroke-width: 2.0px; /* Width of the outline */
  -webkit-text-stroke-color: white; /* Color of the outline */
  background-color: transparent; /* Transparent fill */
  color: rgba(255, 255, 255, 0.075);; /* Transparent fill */
  display: inline-block;
}

/* Adjust your keyframes */
@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}


.text-slider-item.active {
  opacity: 1;
}

/* ... Your existing CSS below ... */


/* ... Your existing CSS below ... */

/* ... Your existing CSS below ... */


/* ... Your existing CSS below ... */


/* ... Your existing CSS below ... */


.text-slider-item h1 {
  font-size: 4rem; /* Adjust the font size as needed */
  color: #fff;
  text-align: center;
  margin: 0;
  padding: 0; /* Remove excessive padding */
  font-family: 'Open Sans', sans-serif;
}




/* unvisited link */
a:link {
  color: #ff861c;
}

/* visited link */
a:visited {
  color: #ff861c;
}

/* mouse over link */
a:hover {
  color: red;
}

/* selected link */
a:active {
  color: green;
}
/* App.css */

/* Existing styles for larger screens */

/* Responsive styles for smaller screens (e.g., max-width: 768px) */
/* Default styles for larger screens */
/* Add your existing CSS styles here */

/* Media query for screens with a maximum width of 768px (typical for mobile devices) */
@media (max-width: 768px) {
  /* Adjust font sizes for smaller screens */
  body {
    font-size: 16px; /* Example font size */
  }
  
  /* Adjust margins and padding as needed */
  .container {
    margin: 10px; /* Example margin */
  }
  
  /* Additional style adjustments for smaller screens */
}













