@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Open+Sans:wght@800&display=swap');

/* Navbar.css */
.navbar {
  position: fixed; /* Fixed position at the top */
  top: 0;
  left: 0;
  width: 100%;
  display: flex; /* Use flexbox for layout */
  justify-content: space-between; /* Space between elements */
  align-items: center; /* Vertically center elements */
  overflow: hidden;
  z-index: 1000; /* Ensure it's above other elements */
; /* Add background color to ensure it's not transparent */
}

.logo{
  font-size: 3rem;
}

.navbar a {
  color: white;
  text-align: center;
  padding: 15px 15px;
  text-decoration: none;
  font-family: 'Open Sans', sans-serif;
  /* font-size: 3rem; */
}

.nav-links {
  display: flex;
  justify-content: center;
  flex-grow: 1; /* Allows the nav-links to take up the available space */
}

/* Specific styling for the UMEDX logo */
.UMEDX {
  color: #ff861c;
}

/* Specific styling for the Survey link */
.survey-link {
  font-size: 1.25rem; /* Smaller size for the Survey link */
  padding: 15px 15px;
  margin-left: auto; /* Ensures it is pushed to the far right */
  color: white; /* Match the color of other links */
  z-index: 1001; /* Ensure it's above other elements */
  position: relative; /* Make sure it stays in the flow of the layout */
}

.survey-link:hover {
  background-color: #f2ab6d; /* Add hover background color */
  border-radius: 5px;
}

/* Responsive styles for smaller screens (e.g., max-width: 768px) */
@media (max-width: 768px) {
  .navbar {
      padding: 10px 0; /* Reduce padding for smaller screens */
  }

  .navbar a {
      font-size: 14px; /* Reduce font size for links on smaller screens */
  }

  .survey-link {
      font-size: 12px; /* Adjust survey link size for smaller screens */
  }
}
